export const capitalize = (str: string): string => str.split(' ').map(word => word[0].toUpperCase() + word.substring(1).toLowerCase()).join(' ')
export const capitalizeSlug = (str: string) => capitalize(str.replaceAll('-', ' '))
export const slugifyTitle = (title: string) => title.toLowerCase().replace(' ', '-')
export const formatPrice = (price: number) => price.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ').replaceAll('.', ',')
export const declineWordByCount = (count: number): string => {
  if (count === 1) {
    return `${count} Produkt`
  }
  else if (count >= 2 && count <= 4) {
    return `${count} Produkty`
  }
  else {
    return `${count} Produktů`
  }
}
